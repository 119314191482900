<template>
  <div class="outer">
    <SearchPanel
      flex
      show-btn
      @getList="getSearchList"
    >
      <!-- <div>
        <p>状态</p>
        <el-select
          v-model="select"
          placeholder="请选择状态"
          size="mini"
          filterable
          clearable
        >
          <el-option
            v-for="item in selectArr"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div> -->
      <div>
        <p>结算单号</p>
        <el-input
          v-model="settlementNum"
          size="mini"
          placeholder="请输入结算单号"
        />
      </div>
      <div>
        <p>结算单创建起始时间</p>
        <el-date-picker
          v-model="startTime"
          size="mini"
          type="date"
          placeholder="选择结算单创建起始时间"
        >
        </el-date-picker>
      </div>
      <div>
        <p>结算单创建结束时间</p>
        <el-date-picker
          v-model="endTime"
          size="mini"
          type="date"
          placeholder="选择结算单创建结束时间"
        >
        </el-date-picker>
      </div>
    </SearchPanel>
    <SearchCard
      :list="selectArr"
      :select="select"
      place="header"
      @change="changeStyle"
    />
    <el-table
      v-loading="loading"
      :data="tableData"
      :header-cell-style="{'font-weight': 'bold', 'background': '#F0F2F8', 'color': '#333'}"
      border
      element-loading-text="拼命加载中"
      style="width: 100%"
    >
      <el-table-column
        prop="id"
        label="序号"
      >
      </el-table-column>
      <el-table-column
        prop="settlement_sn"
        label="结算单号"
        width="140"
      >
      </el-table-column>
      <el-table-column
        label="结算状态"
        prop="status_name"
      >
      </el-table-column>
      <el-table-column
        label="订单数"
        prop="order_count"
      >
      </el-table-column>
      <el-table-column
        prop="price_fee"
        label="实际销售额"
      >
      </el-table-column>
      <el-table-column
        prop="coupon_fee"
        label="平台补贴额"
      >
      </el-table-column>
      <el-table-column
        prop="sale_price"
        label="售后承担金额"
      >
      </el-table-column>
      <el-table-column
        prop="deduc_price"
        label="扣款金额"
        width="130"
      >
      </el-table-column>
      <el-table-column
        label="结算金额"
        width="120"
      >
        <template slot-scope="scope">
          {{ Number(scope.row.settlement_price).toFixed(2) }}
        </template>
      </el-table-column>
      <el-table-column
        label="最终结算金额"
        prop="settlement_price_last"
        width="120"
      >
      </el-table-column>
      <el-table-column
        prop="range"
        label="结算范围"
        width="240"
      >
      </el-table-column>
      <el-table-column
        prop="create_at"
        label="创建时间"
        width="180"
      >
      </el-table-column>
      <el-table-column
        v-if="isRead === 0"
        label="操作"
        width="130"
        fixed="right"
      >
        <template slot-scope="scope">
          <router-link
            v-if="scope.row.status === 6"
            :to="{path: '/finance/settlementdetail', query: {id: scope.row.id}}"
            target="_blank"
          >
            <el-button type="text">
              去确认
            </el-button>
          </router-link>
          <router-link
            v-if="scope.row.status === 8"
            :to="{path: '/finance/settlementdetail', query: {id: scope.row.id}}"
            target="_blank"
          >
            <el-button type="text">
              查看详情
            </el-button>
          </router-link>
          <el-button
            type="text"
            class="margin-l10"
            @click="downloadList(scope.row)"
          >
            下载
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="pagination.currentPage"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="pagination.nowPageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pagination.count"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
</template>
<script>
/**
 * 供应商结算列表
 */
import { confirm } from '@/api/confirmUrl'

import SearchCard from '@/components/common/SearchCard'

export default {
  name: 'SettlementList',
  components: {
    
    SearchCard
  },
  data() {
    return {
      isRead: 1, // 权限
      startTime: null,
      endTime: null,
      settlementNum: '',
      tableData: [],
      select: '',
      selectArr: [{ label: '全部', value: '' }, { label: '待确认', value: '6' }, { label: '沟通中', value: '8' }, { label: '已确认', value: '3' }, { label: '已收款', value: '4' }],
      loading: false,
      pagination: {
        currentPage: 1,
        nowPageSize: 10,
        count: 0
      }
    }
  },
  mounted() {
    this.isRead = parseInt(sessionStorage.getItem('is_read'), 10)
    this.getList()
  },
  methods: {
    // 点击搜索
    getSearchList() {
      this.pagination.currentPage = 1
      this.getList()
    },
    // 分页控制
    handleSizeChange(val) {
      this.pagination.nowPageSize = val
      this.pagination.currentPage = 1
      this.getList()
    },
    handleCurrentChange(val) {
      this.pagination.currentPage = val
      this.getList()
    },
    // submit(row) {
    //   this.$router.push({ path: '/finance/settlementdetail' })
    // this.$confirm('是否确认该结算信息无误？(确认后按照开票信息及寄送地址邮寄该账单的发票，发票确认后进行打款)', '提示', {
    //   confirmButtonText: '确定',
    //   cancelButtonText: '取消'
    // }).then(() => {
    //   this.$axios({
    //     method: 'post',
    //     url: this.$api.closeOrder.sure_settle,
    //     data: {
    //       id: row.id
    //     }
    //   }).then((res) => {
    //     let r = res
    //     if (r.error === 0) {
    //       this.$message('提交成功')
    //     } else {
    //       this.$message(r.error_msg)
    //     }
    //     this.getList()
    //   })
    // }).catch(() => {

    // })
    // },
    // 导航点击切换
    changeStyle(item) {
      this.select = item.value
      this.pagination.currentPage = 1
      this.getList()
    },
    // 获取列表数据
    getList() {
      this.loading = true
      this.$axios({
        method: 'get',
        url: this.$api.closeOrder.new_settlement_list,
        params: {
          page: this.pagination.currentPage,
          limit: this.pagination.nowPageSize,
          status: this.select,
          start_at: this.startTime !== null ? Date.parse(this.startTime) / 1000 : '',
          end_at: this.endTime !== null ? Date.parse(this.endTime) / 1000 : '',
          settlement_sn: this.settlementNum
        }
      }).then((res) => {
        let r = res
        if (r.error === 0) {
          r.data.list.forEach(item => {
            switch (item.status) {
              case 6:
                item.status_name = '待确认'
                break
              case 8:
                item.status_name = '沟通中'
                break
              case 3:
                item.status_name = '已确认'
                break
              case 4:
                item.status_name = '已收款'
                break
              default:
                item.status_name = ''
            }
          })
          this.tableData = r.data.list
          this.pagination.count = r.data.pagination.item_total
        }
        this.loading = false
      })
    },
    downloadList(row) {
      let message = '是否确认下载对账单数据'
      let url = this.$api.closeOrder.downDetail + '?suppliers_id=' + row.suppliers_id + '&settlement_id=' + row.id
      confirm(message, url)
    }
  }
}
</script>
